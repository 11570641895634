body {
  background-color: #F8F8F8;
  margin: 0;
  padding: 0 0 30px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input:focus,
input:active,
button:focus,
button:active {
  box-shadow: none !important;
}

label {
  font-weight: bold;
}

.Navigation {
  background-color: white;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.nav-link.active {
  font-weight: bold;
  color: black;
}
.Homepage {
  /* put around the vertical center of page */
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
}

.SearchForm input:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.CompanyCard {
  max-height: 250px;
  margin-bottom: 1rem;
  color: inherit;
  border-radius: 0;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.CompanyCard img {
  width: 50px;
}

.CompanyCard:hover { /* <Card /> that is also <Link /> */
  text-decoration: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.LoadingSpinner {
  margin: 200px auto 0 auto;
  color: tomato;
  font-size: 24pt;
  font-weight: bold;
  text-align: center;
}
.JobCard {
  max-height: 250px;
  margin-bottom: 1rem;
  color: inherit;
  border-radius: 0;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

